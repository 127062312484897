<template>
    
    <div>
    <MainBanner
      :Title="this.pageContent.page && this.pageContent.page[0].title"
      Image="static/img/bhb-banner.png"
      TextPosition="between"
      Height="middle"
    />
    <div class="main-continer mt-5" v-if="this.pageContent">
      <div class="container kvkk" v-html="this.pageContent.page[0].content"></div>
    </div>
    <FooterLanding />
  </div>
</template>
<script>
import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";

export default {
  name: "bagisci-haklari-beyannamesi",
  components: { MainBanner, ButtonRounded, FooterLanding },
  data() {
    return {
      disabled: false,
      isLoading: false,
      fullPage: false,
      questionList: {},
      pageContent: "",
    };
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this.getPageContent("bagisci-haklari-beyannamesi");
  },
  methods: {
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
        console.log(this.pageContent);
      });
    },
  },
};
</script>
<style lang="scss">
.kvkk {
  padding-bottom: 100px;
  ul {
    li {
      font-size: 1rem;
    }
  }
  p {
    font-size: 1rem;
    padding: 1rem 0;
  }
  h3 {
    font-size: 2rem;
    padding: 1rem 0;
  }
  h4 {
    font-size: 1.5rem;
    padding: 1rem 0;
  }
}
.accordion__wrapper {
  border-top: 2px solid rgba(10, 38, 57, 0.1);

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2rem 0px;
  }
  p {
    font-size: 1rem;
    padding-bottom: 4rem;
  }
  span {
    width: 2rem;
    height: 2rem;
    background: var(--purpleDark);
    transition: 0.3s ease;
  }
  .accordion__head {
    cursor: pointer;
  }
  .active {
    span {
      transform: rotate(-180deg);
    }
  }
  .accordion__body {
    display: none;
  }
}
</style>